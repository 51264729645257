/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-ei2l74 bg--top --full" name={"uvod"} fullscreen={true} css={css`
        
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4" anim={"2"} animS={"4"} style={{"maxWidth":928}}>
              
              <Title className="title-box fs--86 title-box--invert lh--12" content={"Poctivé české pekařství s historií & tradicí\n"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" href={"#sluzby"} content={"Naše pečivo"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"uvod-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 pb--40 pt--50" anim={"2"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4" anim={"2"} animS={"4"}>
              
              <Title className="title-box" style={{"maxWidth":872}} content={"Pečeme tradiční české pečivo na základě domácích receptur a z výhradně odpovědných potravin — od housek až po preclíky."}>
              </Title>

              <Button className="btn-box btn-box--hvr2" href={"#sluzby"} content={"Na zakázku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-qde8ls --border2 js-anim  --anim6 --anim-s4 bg--center --full --parallax pb--60 pt--60" anim={"6"} name={"obrazek"} animS={"4"} border={"2"} parallax={true} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/96/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"cenik"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--50 pt--40 flex--top" style={{"maxWidth":1150}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box lh--1" content={"Naše pečivo"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

              <Text className="text-box mt--60" style={{"maxWidth":254}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

              <Text className="text-box mt--60" style={{"maxWidth":254}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex --center el--2 pb--0 pl--06 pr--06 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 --right" anim={"2"} animS={"4"} border={null}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/96/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/96/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/96/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/96/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/96/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/96/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box pl--0 pr--0" content={"Chléb vícezrnný, 500g  —  38 Kč"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 --left mt--80 pt--40" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/96/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/96/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/96/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/96/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/96/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/96/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box pl--0 pr--0" content={"Chléb vícezrnný, 500g  —  38 Kč"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--80" name={"navstivte-nas"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 pb--80 pt--80" anim={"2"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 lh--1" style={{"maxWidth":872}} content={"Přímo z pece."}>
              </Title>

              <Button className="btn-box" href={"/"} content={"Navštivte nás"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pl--40 pr--40 pt--40" name={"paticka"}>
          
          <ColumnWrap className="column__flex el--3 --full pl--20 pr--20 flex--bottom" style={{"maxWidth":""}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Pekařství Holandská 854/1<br>Holandská 854/1, Brno<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"+420 797 811 2X0<br>info@vase-stranky.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Těšíme se na Vás."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Po—Pá<br>7:00—15:00<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"So—Ne<br>7:00—15:00<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}